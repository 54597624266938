import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {
  Typography,
  Button,
  Box,
  TextField,
  Paper,
  Select,
  ListItemText,
  Checkbox,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { navigate } from 'gatsby';
// import TagManager from 'react-gtm-module';
import * as queryString from 'query-string';
import { SERVICE_OPTIONS, REGION_OPTIONS } from '../../utils/constants';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    color: 'black',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2E2E2',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    fontSize: '14px',
    fontFamily: 'Open sans',
    width: '260px',
    padding: '5px',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '24px 20px',
    margin: '0',
    borderRadius: '0',
    width: '100%',
    boxShadow: 'none',
    backgroundColor: theme.palette.coreYellow,
    [theme.breakpoints.up('lg')]: {
      padding: '26px',
      height: '528px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '26px',
      height: '528px',
    },
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    '& a': {
      color: theme.palette.coreBlue,
      paddingTop: '15px',
    },
  },
  button_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '20px',
  },
  search_button: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  national_services: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  text_field: {
    minHeight: '55px',
  },
  headerTop: {
    paddingTop: '0',
    fontWeight: 'bold',
  },
  headers: {
    paddingTop: '17px',
    fontWeight: 'bold',
  },
  dropdown: {
    maxHeight: '55vh',
  },
  dropdown_item: {
    '&:hover': {
      backgroundColor: '#FFF4BB',
    },
  },
  reset: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  InfoIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
  },
  checkBox: {
    padding: '0px',
  },
}));

const postcodeRegex = RegExp(
  /\b((?:(?:gir)|(?:[a-pr-uwyz])(?:(?:[0-9](?:[a-hjkpstuw]|[0-9])?)|(?:[a-hk-y][0-9](?:[0-9]|[abehmnprv-y])?)))) ?([0-9][abd-hjlnp-uw-z]{2})\b/gi,
);
/**
 * Search component that allows a user to search for cancer related services
 * @param {object} location
 */
const SearchMenu = ({ location }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  let postCodeError = false;

  const queryParamsObj = queryString.parse(location.search);
  if (queryParamsObj.includeNational) {
    queryParamsObj.includeNational = queryParamsObj.includeNational === 'true';
  }

  const [postcode, setPostcode] = useState(
    (queryParamsObj &&
      (queryParamsObj.postcode ||
        queryParamsObj.city ||
        queryParamsObj.region)) ||
      '',
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [keywords, setKeywords] = useState(
    queryParamsObj && queryParamsObj.keywords ? queryParamsObj.keywords : [],
  );
  const [serviceType, setServiceType] = useState(
    queryParamsObj && queryParamsObj.serviceType
      ? queryParamsObj.serviceType
          .split(',')
          .filter((type) => SERVICE_OPTIONS.includes(type))
      : [],
  );
  const [includeNational, setIncludeNational] = useState(
    queryParamsObj && queryParamsObj.includeNational === true
      ? queryParamsObj.includeNational
      : false,
  );

  const handleServiceTypeChange = (event) => setServiceType(event.target.value);

  const handlePostcodeChange = (event) => {
    setPostcode(event.target.value);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleClickOpen = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleKeywordChange = (event) => {
    setKeywords(event.target.value);
  };

  const resetFilters = () => {
    setPostcode('');
    setKeywords([]);
    setServiceType([]);
    setIncludeNational(false);
  };

  const handleSearch = () => {
    const searchParams = {};

    if (REGION_OPTIONS.includes(postcode.toLowerCase())) {
      searchParams.region = postcode.toLowerCase();
    } else if (postcode.toLowerCase().match(postcodeRegex)) {
      searchParams.postcode = postcode.toLowerCase();
      searchParams.radius =
        queryParamsObj && queryParamsObj.radius ? queryParamsObj.radius : 30;
    } else if (postcode !== '') {
      searchParams.city = postcode.toLowerCase();
      searchParams.radius =
        queryParamsObj && queryParamsObj.radius ? queryParamsObj.radius : 30;
    }

    if (keywords.length > 0) {
      searchParams.keywords = keywords.replace(/\s\s+/g, ' ').toLowerCase();
    }

    if (serviceType.length > 0) {
      searchParams.serviceType = serviceType;
    }

    searchParams.includeNational = includeNational;

    if (
      !includeNational &&
      postcode.trim().length <= 0 &&
      serviceType.length === 0
    ) {
      postCodeError = true;
      setErrorMessage(true);
    }
    if (!postCodeError) {
      setErrorMessage(false);
      sessionStorage.setItem('searchPagePointer', '0');
      const params = Object.keys(searchParams)
        .map((key) => `${key}=${encodeURIComponent(searchParams[key])}`)
        .join('&');
      navigate(`/search?${params}`);
    }
  };

  return (
    <>
      <Paper
        classes={{
          root: classes.root,
        }}
      >
        <Box width={{ xs: 1, lg: 460 }}>
          <Box width={1} mb={2}>
            <Typography className={classes.headerTop} variant="h4">
              By keyword
            </Typography>
            <TextField
              id="keywordSearchInput"
              className={classes.text_field}
              fullWidth
              placeholder="e.g. Breast cancer"
              variant="filled"
              value={keywords}
              onChange={handleKeywordChange}
            />
          </Box>
          <Box width={1} mb={2}>
            <Typography className={classes.headers} variant="h4">
              By postcode, city or region
            </Typography>
            <TextField
              error={errorMessage}
              id="postcodeSearchInput"
              className={classes.text_field}
              fullWidth
              placeholder="e.g. BR6 7NZ"
              variant="filled"
              value={postcode}
              onChange={handlePostcodeChange}
              inputProps={{
                onKeyUp: (e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                },
              }}
              helperText={
                errorMessage ? 'Please input a Postcode, city or region' : ''
              }
            />
          </Box>

          <Box width={1} mb={2}>
            <Typography className={classes.headers} variant="h4">
              By service type
            </Typography>
            <Box mb={7} mt={3}>
              <Select
                multiple
                SelectDisplayProps={{
                  'data-testid': 'service-select-box',
                }}
                inputProps={{
                  'data-testid': 'service-select-input',
                  placeholder: 'test',
                }}
                displayEmpty
                value={serviceType}
                data-testid="servicesDropdown"
                onChange={handleServiceTypeChange}
                renderValue={(selected) =>
                  `${
                    selected.length === 0
                      ? 'Select from list'
                      : `(${selected.length})`
                  } ${selected.join(', ')}`
                }
                fullWidth
                variant="filled"
                MenuProps={{
                  classes: { paper: classes.dropdown },
                  'data-testid': 'service-select-menu',
                  anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  getContentAnchorEl: null,
                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                {SERVICE_OPTIONS.map((option) => (
                  <MenuItem
                    key={`${option}`}
                    value={`${option}`}
                    data-testid={`${option}`}
                    className={classes.dropdown_item}
                  >
                    <Checkbox
                      checked={serviceType.includes(`${option}`)}
                      color="primary"
                    />
                    <ListItemText primary={`${option}`} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          <Box className={classes.search_container}>
            <Box className={classes.national_services}>
              <Checkbox
                checked={includeNational}
                color="primary"
                id="includeNational"
                onChange={() => setIncludeNational(!includeNational)}
                className={classes.checkBox}
              />
              <Typography>Include National Services</Typography>
              <LightTooltip
                title="Tick to include national organisations such as helplines, mobile apps and online support in your search results"
                placement={isMobile ? 'top-start' : 'right-start'}
                arrow
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}
                open={tooltipOpen}
                disableFocusListener
                disableTouchListener
              >
                <InfoIcon
                  onClick={handleClickOpen}
                  className={classes.InfoIcon}
                />
              </LightTooltip>
            </Box>
            <Box className={classes.container}>
              <Box className={classes.button_container}>
                <Button
                  id="searchButton"
                  color="primary"
                  variant="contained"
                  onClick={handleSearch}
                  className={classes.search_button}
                >
                  Find Services
                </Button>
                {typeof window !== 'undefined' &&
                  window.location.href.includes('search') && (
                    <span
                      role="button"
                      data-testid="resetFilters"
                      onKeyDown={resetFilters}
                      onClick={resetFilters}
                      tabIndex={0}
                      className={classes.reset}
                    >
                      <Typography variant="body1">Reset Filters</Typography>
                    </span>
                  )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

SearchMenu.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default SearchMenu;
