export const DEFAULT_RESULTS_PER_PAGE = 20;
export const RESULTS_PER_PAGE_OPTIONS = [20, 30, 40, 50];
export const DEFAULT_BLOG_RESULTS_PER_PAGE = 5;
export const SERVICE_OPTIONS = [
  'Bereavement and Loss',
  'Body Image and Personal Care',
  'Clinical Services',
  'Complementary Therapies',
  'Diet and Nutrition',
  'Employment',
  'End of Life Care',
  'Exercise and Fitness',
  'Families & Children',
  'Financial and Welfare Advice',
  'Groups and Classes',
  'Helplines',
  'Holidays and retreats',
  'Insurance',
  'Legal Advice and Will Writing',
  'Mobile Apps',
  'Occupational Health',
  'Practical Help',
  'Psycho-sexual Therapy',
  'Psychological Support and Counselling',
  'Support for Carers',
  'Transport and Travel',
  'General Information and Advice',
];
export const REGION_OPTIONS = [
  'scotland',
  'northern ireland',
  'wales',
  'north east',
  'north west',
  'yorkshire and the humber',
  'west midlands',
  'east midlands',
  'south west',
  'south east',
  'east of england',
  'london',
];
export const RADIUS_OPTIONS = [5, 10, 20, 30, 50, 100];
